<template>
  <component :is="component"></component>
</template>

<script>
import { mapGetters } from 'vuex';
import PersonaConstants from '@/shared/constants/PersonaConstants';
export default {
  name: 'ProductListManager',

  components: {
    BuyerProductList: () => import('./BuyerProductList'),
    VendorProductList: () => import('./VendorProductList'),
    AdminVendorProductList: () => import('./AdminVendorProductList'),
    SuperProductList: () => import('./SuperProductList'),
  },

  computed: {
    ...mapGetters('auth', ['user']),
    component() {
      const { persona } = this.user || '';
      switch (persona) {
        case PersonaConstants.super.value:
          return 'SuperProductList';
        case PersonaConstants.vendor.value:
          return 'AdminVendorProductList'
        case PersonaConstants.buyer.value:
        default:
          return 'BuyerProductList';
      }
    },
  },
};
</script>
